export const terminationReasonValues = [
  'FA - Arrêt Activité',
  'FI - Impayé',
  'FE - Erreur Contrat',
  'FL - A trouvé une autre assurance',
  'FS - A jamais exercé (Stop)',
  'FT - Test',
  'FAU - Autre',
  'FINS - Ne peut pas résilier son ancienne assurance',
  'CA - Arrêt Activité',
  'CI - Impayé',
  'CE - Erreur Contrat',
  'CL - A trouvé une autre assurance',
  'CS - A jamais exercé (Stop)',
  'CAU - Autre',
  'CINS - Ne peut pas résilier son ancienne assurance',
  'FC - Ne peut pas être couvert',
  'CC - Ne peut pas être couvert',
] as const

export type TerminationReason = (typeof terminationReasonValues)[number]

const terminationReasonSet = new Set<string>(terminationReasonValues)
export function isTerminationReason(value: string): value is TerminationReason {
  return terminationReasonSet.has(value)
}

/**
 * Some termination reasons void the contract, meaning that no invoice can be created for the contract,
 * even from the period before the termination.
 * This shouldn't happen after this commit but it happened in the past, so we need to add code that will
 * take the reason into account when iterating over the invoicing periods.
 */
export const terminationReasonVoidsContract: Record<TerminationReason, boolean> = {
  'FA - Arrêt Activité': false,
  'FI - Impayé': false,
  'FE - Erreur Contrat': true,
  'FL - A trouvé une autre assurance': false,
  'FS - A jamais exercé (Stop)': true,
  'FT - Test': true,
  'FAU - Autre': true,
  'FINS - Ne peut pas résilier son ancienne assurance': true,
  'CA - Arrêt Activité': false,
  'CI - Impayé': false,
  'CE - Erreur Contrat': true,
  'CL - A trouvé une autre assurance': false,
  'CS - A jamais exercé (Stop)': false,
  'CAU - Autre': false,
  'CINS - Ne peut pas résilier son ancienne assurance': false,
  'FC - Ne peut pas être couvert': true,
  'CC - Ne peut pas être couvert': true,
}
